import cx from "clsx";

type HeadingElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

interface HeadingProps extends HeadingElementProps {
  className?: string;
  leadingClassName?: string;
  tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const Heading = ({
  children,
  className,
  leadingClassName = "leading-tight",
  tag: Component,
  ...otherProps
}: HeadingProps) => {
  return (
    <Component className={cx("font-serif font-bold", leadingClassName, className)} {...otherProps}>
      {children}
    </Component>
  );
};

export default Heading;
