/* eslint-disable @typescript-eslint/no-explicit-any */

import cx from "clsx";
import React from "react";

interface SpaceBetweenProps {
  children: any;
  spacing: string;
}

const SpaceBetween = ({ children, spacing }: SpaceBetweenProps) => {
  let kids = children;
  if (children.type === React.Fragment) {
    kids = children.props.children;
  }
  if (typeof kids === "string") {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{kids}</>;
  }
  const childCount = React.Children.count(kids);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {React.Children.map(
        kids,
        (child: React.ReactElement<any>, i) =>
          child &&
          React.cloneElement(child, {
            className: cx(child.props.className, { [spacing]: i !== childCount - 1 }),
          })
      )}
    </>
  );
};

export default SpaceBetween;
