import cx from "clsx";
import NextImage, { type ImageProps as NextImageProps } from "next/image";
import { Suspense } from "react";

export type ImageProps = NextImageProps;

export default function Image({ className, ...props }: ImageProps) {
  return (
    <Suspense>
      <NextImage className={cx("object-contain", className)} {...props} />
    </Suspense>
  );
}
