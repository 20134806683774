import Link from "~/components/common/Link";
import { isExternalUrl } from "~/helpers/urls";

interface MenuLinkProps {
  navItem: {
    label: string;
    url: string;
  };
  styles?: {
    activeLink?: string;
    link: string;
  };
}

const MenuLink = ({ navItem, styles }: MenuLinkProps) => {
  if (!navItem) {
    throw new Error("Navigation navItem is required.");
  }
  const { url = "" } = navItem;

  if (isExternalUrl(url)) {
    return (
      <li>
        <a className={styles?.link} href={url} rel="noopener noreferrer" target="_blank">
          {navItem.label}
        </a>
      </li>
    );
  }

  return (
    <li>
      <Link activeClassName={styles?.activeLink} className={styles?.link} href={url}>
        {navItem.label}
      </Link>
    </li>
  );
};

export default MenuLink;
