import cx from "clsx";
import AddressBody from "~/components/common/Address/AddressBody";
import LinkButton from "~/components/common/Button/LinkButton";
import Container from "~/components/common/Container";
import Heading from "~/components/common/Heading";
import Link from "~/components/common/Link";
import Logo from "~/components/common/Logo";
import MenuLink from "~/components/common/MenuLink";
import Paragraph from "~/components/common/Paragraph";
import { type SocialItem } from "~/constants/social";
import {
  CONTACT_US_URL,
  HOME_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITION_URL,
} from "~/constants/urls";
import { useSiteMetadata } from "~/hooks/useSiteMetadata";

interface SocialMediaProps {
  social: SocialItem;
}

const SocialMediaIcon = ({ social: { iconUrl, url, username } }: SocialMediaProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <a
      className="relative block size-5"
      href={url}
      rel="noopener noreferrer"
      target="_blank"
      title={username}
    >
      <img alt="" src={iconUrl} />
    </a>
  );
};

const styles = {
  columnLarge: cx("flex w-full lg:w-8/12"),
  columnSmall: cx("flex w-full lg:w-4/12"),
  link: cx("px-1 text-blue-500"),
};

const Footer = () => {
  const { menuLinks, social } = useSiteMetadata();

  return (
    <footer
      className="mt-auto bg-gray-900 py-16 leading-relaxed text-gray-400 dark:border-t-2 dark:border-black"
      data-testid="root-footer"
    >
      <Container className="flex-col lg:flex">
        <div className="flex flex-wrap">
          <div className={styles.columnSmall}>
            <Link className="mb-8" href={HOME_URL}>
              <Logo height={50} />
            </Link>
          </div>
          <Paragraph
            className={cx(
              styles.columnLarge,
              "order-3 mb-8 font-serif text-xl font-light lg:order-2 lg:text-2xl"
            )}
          >
            Need to turn an idea into software but you don’t know where to start? Let’s do this
            together. Work with our incredible team and go get your dream.
          </Paragraph>
          <ul
            className={cx(styles.columnSmall, "order-2 mb-8 flex-col gap-4")}
            data-testid="root-footer-links"
          >
            {menuLinks.map((navItem, i) => (
              <MenuLink key={i} navItem={navItem} />
            ))}
          </ul>
          <div className={cx(styles.columnLarge, "order-4 flex-col")}>
            <address className="not-italic">
              <Heading className="mb-2 block text-2xl text-white" tag="h2">
                Colorado
              </Heading>
              <AddressBody />
            </address>
            <div className="my-12">
              <LinkButton color="red" href={CONTACT_US_URL} size="md">
                Have a question? Contact Us!
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between gap-12 lg:flex-row lg:gap-0">
          <div className="text-xs">
            &copy;{new Date().getFullYear()} Uplift Agency Ltd.
            <Link className={styles.link} href={PRIVACY_POLICY_URL}>
              Privacy Policy
            </Link>
            &#124;
            <Link className={styles.link} href={TERMS_AND_CONDITION_URL}>
              Terms of Service
            </Link>
          </div>
          <div className="flex gap-12 lg:gap-4">
            <SocialMediaIcon social={social.instagram} />
            <SocialMediaIcon social={social.twitter} />
            <SocialMediaIcon social={social.facebook} />
            <SocialMediaIcon social={social.linkedin} />
            <SocialMediaIcon social={social.github} />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
