import cx from "clsx";

interface AddressBodyProps {
  linkClassName?: string;
}

const AddressBody = ({ linkClassName }: AddressBodyProps) => {
  return (
    <>
      2480 Kittredge Loop Dr #932
      <br />
      Boulder, CO 80310
      <br />
      <a className={cx("text-red-500", linkClassName)} href="tel:+14154183420">
        (415) 418-3420
      </a>
    </>
  );
};

export default AddressBody;
