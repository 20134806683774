import logoSquare from "~/images/icon-square.png";
import logoDark from "~/images/uplift-logo.png";
import logoLight from "~/images/uplift-logo-inverse.png";

export type LogoVariation = "dark" | "light" | "square";

const files: Record<LogoVariation, string> = {
  dark: logoDark,
  light: logoLight,
  square: logoSquare,
};

interface Dimensions {
  height: number;
  width: number;
}

const dimensions: Record<LogoVariation, Dimensions> = {
  dark: { height: 100 / 2, width: 316 / 2 },
  light: { height: 100 / 2, width: 316 / 2 },
  square: { height: 512 / 2, width: 512 / 2 },
};

export const useLogo = (variation: LogoVariation = "light") => {
  const file = files[variation];

  if (!file) {
    throw new Error("Unable to find logo file");
  }

  const dimension = dimensions[variation];

  if (!dimension) {
    throw new Error("Unable to find logo dimensions");
  }

  return {
    dimensions: dimension,
    src: file as string,
    title: "Uplift.ltd Logo",
  };
};
