import React from "react";
import Link, { LinkProps } from "~/components/common/Link";
import { buttonStyles } from "./Button.styles";
import { ButtonCommonProps } from "./Button.types";

type LinkButtonProps = ButtonCommonProps & Pick<LinkProps, "className" | "href" | "rel" | "target">;

const LinkButton = ({
  children,
  className,
  color = "blue",
  loading,
  size = "md",
  ...otherProps
}: React.PropsWithChildren<LinkButtonProps>) => {
  return (
    <Link className={buttonStyles({ className, loading, size, theme: color })} {...otherProps}>
      {children}
    </Link>
  );
};

export default LinkButton;
