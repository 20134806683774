"use client";

import cx from "clsx";
import Script from "next/script";
import React, { useEffect, useRef, useState } from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import Footer from "~/components/common/Footer";
import Header from "~/components/common/Header";
import { fadeInStyles } from "~/components/common/Transitions";
import { RECAPTCHA_SITE_KEY } from "~/constants/constants";

interface LayoutProps {
  afterBody?: React.ReactNode;
  afterFooter?: React.ReactNode;
  afterHeader?: React.ReactNode;
  beforeBody?: React.ReactNode;
  beforeFooter?: React.ReactNode;
  beforeHeader?: React.ReactNode;
  noFadeIn?: boolean;
}

const Layout = ({
  afterBody,
  afterFooter,
  afterHeader,
  beforeBody,
  beforeFooter,
  beforeHeader,
  children,
  noFadeIn,
}: React.PropsWithChildren<LayoutProps>) => {
  const [isMounted, setIsMounted] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  // we want SSR to serve with opacity: 0 so we don't get a flash, so we use this for preEnter
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <Script src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`} />
      <div className="flex min-h-screen flex-col">
        {beforeHeader}
        <Header />
        {afterHeader}
        {beforeBody}
        <CSSTransition
          classNames={fadeInStyles}
          in={isMounted || noFadeIn}
          nodeRef={ref}
          timeout={800}
        >
          <div className={cx("grow", !isMounted && fadeInStyles.preEnter)} ref={ref}>
            {children}
          </div>
        </CSSTransition>
        {afterBody}
        {beforeFooter}
        <Footer />
        {afterFooter}
      </div>
    </>
  );
};

export default Layout;
