"use client";

import cx from "clsx";
import { useState } from "react";
import Button from "~/components/common/Button";
import Container from "~/components/common/Container";
import Link from "~/components/common/Link";
import Logo from "~/components/common/Logo";
import SpaceBetween from "~/components/common/SpaceBetween";
import { HOME_URL } from "~/constants/urls";
import { useSiteMetadata } from "~/hooks/useSiteMetadata";

const linkClassName = cx("block px-6 py-3 text-gray-400 lg:px-0 lg:py-6");

const Header = () => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const siteMetadata = useSiteMetadata();
  return (
    <div
      className="border-b-4 border-orange-500 bg-gray-900 dark:border-b-2 dark:border-black"
      data-testid="root-header"
    >
      <Container className="flex min-h-[4.5rem] flex-wrap items-center">
        <Link className="shrink-0" data-testid="root-header-logo-link" href={HOME_URL}>
          <Logo height={40} />
        </Link>

        <Button
          className="my-6 ml-auto lg:hidden"
          onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
        >
          Menu
        </Button>

        {siteMetadata && (
          <ul
            className={cx("sr-only font-medium uppercase lg:not-sr-only lg:ml-auto lg:flex", {
              "not-sr-only grow min-w-full py-6 -mx-6 border-t border-black": mobileMenuVisible,
            })}
            data-testid="root-header-links"
          >
            <SpaceBetween spacing="lg:mr-6">
              {siteMetadata.menuLinks.map(navItem => {
                if (!navItem) {
                  throw new Error("Navigation navItem is required.");
                }
                const { url = "" } = navItem;
                if (url.match(/^\s?http(s?)/gi)) {
                  return (
                    <li key={url}>
                      <a
                        className={linkClassName}
                        href={url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {navItem.label}
                      </a>
                    </li>
                  );
                }
                return (
                  <li key={url}>
                    <Link activeClassName="text-white" className={linkClassName} href={url}>
                      {navItem.label}
                    </Link>
                  </li>
                );
              })}
            </SpaceBetween>
          </ul>
        )}
      </Container>
    </div>
  );
};

export default Header;
