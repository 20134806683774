export interface SocialItem {
  iconUrl: string;
  url: string;
  username: string;
}

export const social = {
  facebook: {
    iconUrl: "/img/logo/facebook.svg",
    url: "https://www.facebook.com/upliftltd",
    username: "upliftltd",
  },
  github: {
    iconUrl: "/img/logo/github.svg",
    url: "https://github.com/uplift-ltd",
    username: "uplift-ltd",
  },
  instagram: {
    iconUrl: "/img/logo/instagram.svg",
    url: "https://www.instagram.com/upliftagencyltd/",
    username: "upliftagencyltd",
  },
  linkedin: {
    iconUrl: "/img/logo/linkedin.svg",
    url: "https://www.linkedin.com/company/uplift-agency/",
    username: "uplift-agency",
  },
  twitter: {
    iconUrl: "/img/logo/twitter.svg",
    url: "https://twitter.com/upliftltd",
    username: "upliftltd",
  },
} satisfies Record<string, SocialItem>;
