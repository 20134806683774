import { SITE_URL } from "~/constants/constants";
import { social } from "~/constants/social";

function getSiteUrl() {
  // on production use NEXT_PUBLIC_SITE_URL
  if (SITE_URL) {
    return SITE_URL;
  }
  // on localhost and preview use window or VERCEL_URL.
  // VERCEL_URL is not available in the browser (not prefixed with NEXT_PUBLIC_)
  if (typeof window !== "undefined") {
    return window.origin;
  }
  // window is not available on the server
  return "https://www.uplift.ltd";
}

export const useSiteMetadata = () => {
  return {
    coverImage: null,
    // This allows an alternative site title for meta data for pages.
    description: `Build products that are a joy to use.`,

    icon: "/img/logo/icon-square.png",
    logo: "/img/logo/uplift-logo.png",

    // https://www.gatsbyjs.com/docs/creating-dynamic-navigation/
    menuLinks: [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Blog",
        url: "/blog",
      },
      {
        label: "Careers",
        url: "/careers",
      },
      {
        label: "About Us",
        url: "/about-us",
      },
      {
        label: "Contact Us",
        url: "/contact-us",
      },
    ],
    shareImageHeight: 600,
    // Meta Image Size
    shareImageWidth: 900,

    siteIcon: `/img/logo/favicon@3x.png`,

    // This allows an alternative site description for meta data for pages.
    siteUrl: getSiteUrl(),
    social,

    // Header Navigation
    title: `Uplift Ltd`,
  };
};
