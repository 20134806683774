import Image from "~/components/common/Image";
import { type LogoVariation, useLogo } from "~/hooks/useLogo";

interface LogoProps {
  className?: string;
  height?: number;
  imgClassName?: string;
  variation?: LogoVariation;
  width?: number;
}

const Logo = ({ className, height, imgClassName, variation, width }: LogoProps) => {
  const { dimensions, src, title } = useLogo(variation);

  let finalWidth = dimensions.width;
  let finalHeight = dimensions.height;

  if (width || height) {
    if (width) {
      finalWidth = width;
      finalHeight = (width * dimensions.height) / dimensions.width;
    } else if (height) {
      finalHeight = height;
      finalWidth = (height * dimensions.width) / dimensions.height;
    }
  }

  return (
    <div className={className}>
      <Image
        alt={title}
        className={imgClassName}
        height={finalHeight}
        src={src}
        width={finalWidth}
      />
    </div>
  );
};

export default Logo;
