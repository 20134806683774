import cx from "clsx";

type ParagraphElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

interface ParagraphProps extends ParagraphElementProps {
  className?: string;
}

const Paragraph = ({ children, className, ...otherProps }: ParagraphProps) => {
  return (
    <p className={cx("leading-normal", className)} {...otherProps}>
      {children}
    </p>
  );
};

export default Paragraph;
